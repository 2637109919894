/*
<<<<<<< HEAD
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 16px
*/
/* required styles */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url(//api.mapbox.com/mapbox.js/v2.2.2/mapbox.css);
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast; }

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important; }

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y; }

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom; }

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none; }

.leaflet-container {
  -webkit-tap-highlight-color: transparent; }

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4); }

.leaflet-tile {
  filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-pane {
  z-index: 400; }

.leaflet-tile-pane {
  z-index: 200; }

.leaflet-overlay-pane {
  z-index: 400; }

.leaflet-shadow-pane {
  z-index: 500; }

.leaflet-marker-pane {
  z-index: 600; }

.leaflet-tooltip-pane {
  z-index: 650; }

.leaflet-popup-pane {
  z-index: 700; }

.leaflet-map-pane canvas {
  z-index: 100; }

.leaflet-map-pane svg {
  z-index: 200; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity; }

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-interactive {
  cursor: pointer; }

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab; }

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none; }

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0; }

.leaflet-container a {
  color: #0078a8; }

.leaflet-container a.leaflet-active {
  outline: 2px solid orange; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif; }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px; }

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black; }

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block; }

.leaflet-bar a:hover {
  background-color: #f4f4f4; }

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb; }

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px; }

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px; }

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff; }

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png); }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover {
  text-decoration: underline; }

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5); }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px; }

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4; }

.leaflet-popup-content p {
  margin: 18px 0; }

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none; }

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999; }

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px; }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666; }

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); }

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto; }

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px; }

.leaflet-tooltip-top {
  margin-top: -6px; }

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px; }

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff; }

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff; }

.leaflet-tooltip-left {
  margin-left: -6px; }

.leaflet-tooltip-right {
  margin-left: 6px; }

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px; }

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff; }

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(253, 111, 95, 0.438); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(253, 111, 95, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(253, 111, 95, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(253, 111, 95, 0.438);
    box-shadow: 0 0 0 0 rgba(253, 111, 95, 0.438); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(253, 111, 95, 0);
    box-shadow: 0 0 0 10px rgba(253, 111, 95, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(253, 111, 95, 0);
    box-shadow: 0 0 0 0 rgba(253, 111, 95, 0); } }

.reports-page__report-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: #fff;
  padding: 0 2.5rem; }
  @media (min-width: 56.25em) {
    .reports-page__report-container {
      justify-content: flex-start; } }

.no-reports {
  display: flex; }
  .no-reports__container {
    display: flex;
    align-items: center;
    padding: 2rem;
    background-color: var(--grey-light-2);
    width: 100%;
    border-radius: 5px;
    box-shadow: var(--shadow-light);
    margin: 1rem 2rem; }
  .no-reports__left-icon {
    padding-right: 2rem;
    display: none; }
    @media (min-width: 37.5em) {
      .no-reports__left-icon {
        display: initial; } }
  .no-reports__text-content {
    display: flex;
    flex-direction: column; }
  .no-reports__text {
    font-size: 1.4rem; }
    @media (min-width: 56.25em) {
      .no-reports__text {
        font-size: 2.3rem; } }
  @media (min-width: 56.25em) {
    .no-reports__sub-text {
      font-size: 1.4rem;
      color: var(--grey-dark-3); } }

.create-report {
  font-size: 1.4rem;
  margin: 2rem 2rem; }
  @media (min-width: 56.25em) {
    .create-report {
      margin: 7rem; } }
  .create-report__title {
    margin-bottom: 1rem;
    font-family: "Oswald";
    font-size: 2rem;
    text-align: left;
    color: var(--grey-dark-2); }
    @media (min-width: 56.25em) {
      .create-report__title {
        font-size: 2.4rem; } }
  .create-report__textfield {
    font-family: "Roboto";
    margin: 0.7rem 0;
    color: var(--grey-dark-2);
    line-height: 2rem;
    font-size: 1.3rem;
    padding: 1rem 0.2rem 0.3rem 0.2rem;
    border: none;
    border-bottom: 1.3px solid var(--grey-dark-3);
    transition: border-bottom 0.2s ease-in-out; }
    .create-report__textfield:focus {
      outline: none;
      border-bottom: 2px solid #290b83 !important; }
      .create-report__textfield:focus::placeholder {
        transition: all 0.14s ease-out;
        -moz-transition: all 0.14s ease-out;
        -webkit-transition: all 0.14s ease-out;
        font-size: 0.3rem;
        transform: translateY(-17px);
        -ms-transform: translateY(-17px);
        -moz-transform: translateY(-17px);
        position: relative; }

.report-form-container {
  width: 100%;
  line-height: 5; }
  @media (min-width: 56.25em) {
    .report-form-container {
      min-width: 60rem; } }

.create-report-label > label {
  font-size: 1.5rem;
  color: var(--grey-dark-2);
  font-weight: 500; }

@media print {
  @page {
    overflow: visible !important;
    margin: 1px; }
  .subNav,
  header,
  #button-share,
  .deleteButton,
  .tabBar,
  .MuiTabs-flexContainer-124,
  .MuiTabs-flexContainer-343,
  .report-header__buttons,
  .header-user-avatar {
    display: none !important; }
  .recharts-wrapper,
  .recharts-surface {
    width: calc(100%) !important; }
  .graph-component {
    background-color: #fff !important; }
  .age-chartList__name {
    white-space: nowrap !important; }
  .age-chartList {
    white-space: nowrap;
    flex-wrap: nowrap !important; }
  .details__title-center--reactions {
    display: none; }
  .details__title-center {
    font-size: 2.5rem !important;
    margin-top: 2rem !important; }
  .report-custom-container {
    page-break-inside: avoid; }
  .reports__heading {
    float: none !important; }
  .article-overview-box {
    flex-direction: row !important;
    width: 100% !important; }
    .article-overview-box__img {
      max-width: 30% !important;
      object-fit: cover !important; }
    .article-overview-box__text {
      font-size: 2rem !important; }
  .details__title-center--fb {
    display: none !important; }
  .details__graph-box--traffic {
    width: 80% !important;
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important; }
  .article-stats-box {
    flex-wrap: nowrap !important;
    padding: 0 !important;
    justify-content: center !important; }
    .article-stats-box__item {
      margin: 0 !important; }
  body {
    font-family: Arial !important; }
  .reports__flex-area--graph {
    flex-direction: row !important; }
  .reports__flex-area--2graphs {
    flex-direction: row !important; }
  .reports__details--reactions {
    width: 80% !important;
    margin: 0 auto !important; }
  .reports__details {
    overflow: visible !important;
    margin: 2rem 0; }
  .reports__content--map {
    display: none !important; }
  .reports__flex-area--fbpaid {
    flex-direction: row !important; }
  .reports-container__print {
    display: unset;
    page-break-inside: avoid !important; }
  .reports-container {
    float: none !important;
    page-break-inside: avoid !important; } }

:root {
  --color-primary: #eb2f64;
  --color-primary-light: #ff3366;
  --color-primary-dark: #ba265d;
  --purple: rgb(69, 40, 130);
  --grey-light-1: #faf9f9;
  --grey-light-2: #f4f2f2;
  --grey-light-3: #f0eeee;
  --grey-light-4: #ccc;
  --grey-dark-1: #333;
  --grey-dark-2: #777;
  --grey-dark-3: #999;
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.2);
  --shadow-light: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --content: #dadada;
  --google: #db3236;
  --yt: #b31217;
  --fb: #3b5998;
  --ig: #517fa4;
  --twitter: #55acee;
  --nmblue: #3f19b5; }

* {
  margin: 0;
  padding: 0; }

*,
*::after,
*::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 67.5%; }

body {
  line-height: 1.6;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: var(--grey-dark-2); }

h1 {
  font-weight: 500; }
  h1 html {
    margin: 0;
    width: 100%; }
  h1 body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    margin: 0 auto;
    height: 100vh;
    width: 100%; }
  h1 .login-button {
    height: 100px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: black;
    max-width: 768px;
    margin: 0 auto; }
  h1 .login-container {
    background: #ffc107;
    width: 100%;
    height: 100vh; }
  h1 .button-text {
    position: relative;
    font-size: 25px;
    color: white;
    line-height: 50px;
    left: 90px; }
  h1 .google-logo {
    height: 50px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 25px 20px; }
  h1 #root {
    width: 100%; }

.container {
  max-width: 130rem;
  min-height: 90rem;
  width: 100%;
  margin: 0 auto; }

.content {
  flex-direction: row; }

/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer; }

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none; }

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  background-color: #000;
  white-space: nowrap;
  max-width: 50rem;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2; }

/* Directions */
/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%; }

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: rgba(51, 51, 51, 0.9); }

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px; }

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px); }

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto; }

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: rgba(51, 51, 51, 0.9); }

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px); }

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%; }

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: rgba(51, 51, 51, 0.9); }

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px); }

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%; }

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: rgba(51, 51, 51, 0.9); }

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px); }

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px; }

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px; }

.homepage {
  margin: 3rem;
  display: flex;
  justify-content: center;
  width: 90%; }
  .homepage .homepage-portals__item {
    flex: 1 1 100%;
    margin: 0.5rem 0 2rem 0;
    height: 16rem;
    cursor: pointer;
    box-shadow: var(--shadow-light);
    transition: all 0.15s;
    color: #fff;
    width: 20rem;
    margin: 2rem; }
    .homepage .homepage-portals__item:hover {
      transform: scale(1.01) translateY(-0.3rem); }
    .homepage .homepage-portals__item:active {
      transform: translateY(-1px); }
    .homepage .homepage-portals__item--CI {
      background-color: var(--nmblue); }
    .homepage .homepage-portals__item--RI {
      background-color: var(--nmblue); }
    .homepage .homepage-portals__item--TI {
      background-color: var(--nmblue); }
    .homepage .homepage-portals__item--PI {
      background-color: var(--nmblue); }
    .homepage .homepage-portals__item--AD {
      background-color: rgba(160, 43, 43, 0.678); }
    @media (min-width: 37.5em) {
      .homepage .homepage-portals__item {
        width: 50rem; } }

.portal-item-icon {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Oswald"; }
  @media (min-width: 37.5em) {
    .portal-item-icon {
      font-size: 4rem; } }

.reactions {
  display: flex;
  width: 100%;
  justify-content: center; }

.reactionsList {
  list-style: none;
  display: flex;
  justify-content: space-evenly; }
  .reactionsList__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem; }
  .reactionsList__names {
    display: flex;
    justify-content: center; }
  .reactionsList__percent {
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--grey-dark-2); }

.age-chart {
  display: flex;
  justify-content: center; }

.age-chartList {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex: 1 1 50%; }
  @media (min-width: 56.25em) {
    .age-chartList {
      flex-wrap: nowrap;
      flex: 1 1 100%;
      white-space: nowrap; } }
  .age-chartList__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem; }
  .age-chartList__names {
    display: flex;
    justify-content: center; }
  .age-chartList__percent {
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--grey-dark-2); }
    @media (min-width: 56.25em) {
      .age-chartList__percent {
        font-size: 1.7rem; } }

.gender-chart {
  display: flex;
  justify-content: center; }

.gender-chartList {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap; }
  .gender-chartList__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.3rem; }
  .gender-chartList__names {
    display: flex;
    justify-content: center; }
  .gender-chartList__percent {
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--grey-dark-2); }
    @media (min-width: 56.25em) {
      .gender-chartList__percent {
        font-size: 1.7rem; } }

.article-overview {
  display: flex;
  justify-content: center;
  width: 90%;
  padding-top: 3rem;
  margin: 0 auto; }

.article-overview-box {
  display: flex;
  justify-content: left;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 1px; }
  @media (min-width: 37.5em) {
    .article-overview-box {
      flex-direction: row;
      width: 100%; } }
  .article-overview-box__img {
    flex: 0 0 32%;
    max-width: 100%;
    max-height: 20rem;
    object-fit: contain; }
    .article-overview-box__img--fb {
      object-fit: cover; }
    @media (min-width: 56.25em) {
      .article-overview-box__img {
        max-width: 100%; }
        .article-overview-box__img--fb {
          flex: 0 0 8%; } }
  .article-overview-box__textBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 1rem; }
  .article-overview-box__published {
    font-family: Arial, sans-serif;
    font-size: 1.2rem; }
  .article-overview-box__view:link, .article-overview-box__view:visited {
    color: var(--purple);
    text-decoration: underline;
    margin-left: 1rem;
    font-size: 1.4rem; }
  .article-overview-box__text {
    font-weight: 300;
    word-spacing: 1px;
    text-transform: capitalize;
    overflow: hidden;
    font-family: "Oswald", sans-serif;
    font-size: 1.8rem;
    color: var(--grey-dark-1);
    margin-right: 0.5rem;
    padding: 5px; }
    @media (min-width: 37.5em) {
      .article-overview-box__text {
        font-size: 3rem; } }

.article-stats {
  margin-top: 2rem; }

.article-stats-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }
  @media (min-width: 56.25em) {
    .article-stats-box {
      flex-wrap: nowrap;
      padding: 1rem;
      border-radius: 5px; } }
  .article-stats-box__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 1 33.333%;
    padding: 0.7rem;
    margin: 1rem; }
    @media (min-width: 56.25em) {
      .article-stats-box__item {
        padding: 0.5rem;
        flex: none;
        box-shadow: none;
        background-color: initial; } }
  .article-stats-box__title {
    font-size: 1.4rem;
    color: var(--grey-dark-2); }
  .article-stats-box__value {
    color: var(--grey-dark-1);
    font-size: 1.6rem;
    font-weight: 700;
    font-family: arial, sans-serif; }

.article-progress {
  margin-top: 2rem; }

.article-progress-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem; }
  .article-progress-box__text {
    font-size: 1.5rem;
    padding: 2rem; }
  .article-progress-box__bar {
    width: 75%; }
  .article-progress-box__booked {
    font-weight: 600;
    color: var(--grey-dark-1); }

.report-header {
  font-size: 1.5rem;
  margin-left: 1rem; }
  @media (min-width: 56.25em) {
    .report-header {
      margin-left: 3rem; } }
  .report-header__client {
    color: var(--grey-dark-1);
    font-size: 1.8rem;
    font-family: "Roboto condensed", sans-serif; }
    @media (min-width: 56.25em) {
      .report-header__client {
        font-size: 2.4rem; } }
  .report-header__container {
    display: flex;
    flex-direction: column; }
    @media (min-width: 56.25em) {
      .report-header__container {
        flex-direction: row;
        justify-content: space-between; } }
  .report-header__left {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column; }
  .report-header__right {
    display: flex;
    flex-direction: column;
    margin-right: 5rem; }
  .report-header__buttons {
    display: flex;
    flex-direction: row; }
  .report-header__generated {
    color: var(--grey-dark-2);
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem; }
    @media (min-width: 56.25em) {
      .report-header__generated {
        font-family: Arial, sans-serif;
        font-size: 1.4rem; } }
  .report-header__sponsored-editorial {
    font-weight: 700;
    font-size: 1.7rem;
    margin-top: 1.5rem;
    color: var(--purple); }
    @media (min-width: 56.25em) {
      .report-header__sponsored-editorial {
        font-family: Arial, sans-serif;
        margin-top: 4rem;
        font-size: 1.7rem;
        font-weight: 700; } }
  .report-header__report-id {
    margin-top: 0.7rem;
    font-size: 1.1rem;
    font-family: Arial, sans-serif; }
    @media (min-width: 56.25em) {
      .report-header__report-id {
        font-size: 1.5rem; } }
  .report-header__report-heading {
    color: var(--grey-dark-1);
    font-size: 2rem;
    font-weight: 800; }
    @media (min-width: 56.25em) {
      .report-header__report-heading {
        font-size: 2.6rem; } }

#button-share {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  align-self: center; }
  @media (min-width: 56.25em) {
    #button-share {
      margin-top: 0;
      transition: all 0.1s; }
      #button-share:hover {
        transform: scale(1.01) translateY(-0.3rem);
        box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.027); }
      #button-share:active {
        transform: translateY(0.02rem); } }

.report-dates {
  margin-top: 1.3rem;
  color: black;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9; }
  .report-dates__container {
    display: flex; }
  .report-dates__date {
    font-size: 1.5rem;
    margin-right: 2rem; }
    @media (min-width: 56.25em) {
      .report-dates__date {
        text-align: left;
        font-size: 1.5rem; } }
  .report-dates__changedate {
    cursor: pointer !important; }
    @media (min-width: 56.25em) {
      .report-dates__changedate:hover {
        color: var(--purple);
        text-decoration: underline; } }
  .report-dates__button {
    color: var(--purple);
    padding: 0.5rem;
    margin-left: 2rem;
    cursor: pointer;
    border: none;
    text-decoration: underline;
    transition: all 0.1s;
    background-color: transparent; }
    .report-dates__button:hover {
      transform: scale(1.02);
      font-weight: 500; }
    .report-dates__button:focus {
      outline: none; }

.tabHeaderLabel {
  font-size: 1rem; }
  @media (min-width: 56.25em) {
    .tabHeaderLabel {
      font-size: 1.3rem; }
      .tabHeaderLabel:hover {
        color: var(--color-primary); } }

.tabHeader {
  background-color: #f9f9f9; }

.print-button__icon {
  padding: 0.6rem 0.6rem 0.6rem 0.2rem;
  display: flex; }
  @media (min-width: 56.25em) {
    .print-button__icon {
      justify-content: center; } }

.refresh-button__icon {
  padding: 0.6rem;
  display: flex; }
  @media (min-width: 56.25em) {
    .refresh-button__icon {
      justify-content: center; }
      .refresh-button__icon:hover {
        transition: transform 0.8s ease-in-out;
        transform: rotate(360deg); } }

.share-notifications {
  position: absolute !important;
  top: 0rem;
  right: 0.4rem; }

.side-nav {
  font-size: 1.4rem;
  margin-top: 3.5rem;
  list-style: none; }
  .side-nav__item {
    position: relative; }
    .side-nav__item:not(:last-child) {
      margin-bottom: 0.5rem; }
  .side-nav__item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: var(--color-primary);
    transform: scaleY(0);
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s; }
  .side-nav__item:hover::before, .side-nav__item--active::before {
    transform: scaleY(1);
    width: 100%; }
  .side-nav__item:active::before {
    background-color: var(--color-primary-light); }
  .side-nav__link:link, .side-nav__link:visited {
    color: var(--grey-light-1);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    display: block;
    padding: 1.5rem 3rem;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center; }
  .side-nav__link span {
    display: none; }
    @media (min-width: 56.25em) {
      .side-nav__link span {
        display: initial; } }
  .side-nav__icon {
    width: 2.2rem;
    height: 2.2rem; }
    @media (min-width: 56.25em) {
      .side-nav__icon {
        margin-right: 2rem;
        width: 1.5rem;
        height: 1.5rem; } }
    .side-nav__icon--content {
      color: var(--content); }
    .side-nav__icon--youtube {
      color: var(--yt); }
    .side-nav__icon--facebook {
      color: var(--fb); }
    .side-nav__icon--facebookPaid {
      color: var(--fb); }
    .side-nav__icon--google {
      color: var(--google); }
    .side-nav__icon--instagramPaid {
      color: var(--ig); }
    .side-nav__icon--twitter {
      color: var(--twitter); }

.legal {
  display: none; }
  @media (min-width: 56.25em) {
    .legal {
      display: initial;
      font-size: 1.2rem;
      color: var(--color-grey-light-4);
      text-align: center;
      padding: 2.5rem; } }

.reports-container {
  margin: 2rem 0; }
  .reports-container__print {
    display: none; }

.reports {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  .reports__header-url:link, .reports__header-url:visited, .reports__header-url {
    color: currentColor;
    text-decoration: none; }
  .reports__components {
    width: 100%;
    margin: 0 auto; }
    .reports__components:not(:last-child) {
      margin-bottom: 0rem; }
    .reports__components--header {
      background-color: #fff; }
  .reports__title {
    color: var(--grey-dark-4);
    padding: 3rem;
    font-size: 1.5rem; }
  .reports__flex-area {
    display: flex;
    flex-direction: column; }
    @media (min-width: 56.25em) {
      .reports__flex-area {
        flex-direction: row; } }
  .reports__content {
    display: flex;
    flex-direction: column; }
    .reports__content--pong {
      align-items: center;
      height: 100%; }
    .reports__content--nopadding {
      padding: 0rem 2rem 2rem 2rem; }
  .reports__details-articleInfo {
    border: 1px dashed rgba(0, 0, 0, 0.075);
    padding: 0.5rem !important;
    margin-bottom: 0 !important; }
  .reports__details, .reports__breakdown {
    flex: 0 1 100%;
    border-radius: 10px;
    margin-bottom: 1rem; }
    @media (min-width: 56.25em) {
      .reports__details, .reports__breakdown {
        flex: 0 1 50%;
        margin-bottom: 0;
        margin: 0 2rem; }
        .reports__details--pong, .reports__breakdown--pong {
          width: 80%; }
        .reports__details--full, .reports__breakdown--full {
          flex: 0 1 100%; }
        .reports__details:not(:last-child), .reports__breakdown:not(:last-child) {
          margin-right: 1rem; } }
    .reports__details--pong, .reports__breakdown--pong {
      width: 100%; }
    @media (min-width: 37.5em) {
      .reports__details--full, .reports__breakdown--full {
        flex: 0 1 100%; } }
    .reports__details--nomargin, .reports__breakdown--nomargin {
      padding: 0;
      margin: 0; }
  .reports__fb-info {
    display: flex;
    align-items: center; }
  .reports__header-bar-article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-light-3); }
    .reports__header-bar-article--center {
      justify-content: center; }
  .reports__header-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--grey-light-3);
    margin: 0 0.5rem; }
    .reports__header-bar--center {
      justify-content: center; }
  .reports__header-bar-img {
    object-fit: cover;
    width: 120px;
    height: 80px; }
    @media (min-width: 56.25em) {
      .reports__header-bar-img {
        width: 150px;
        height: 100px; } }
  .reports__header-bar-icon:link, .reports__header-bar-icon:visited, .reports__header-bar-icon {
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    fill: var(--grey-dark-2);
    color: var(--grey-dark-2); }
    @media (min-width: 56.25em) {
      .reports__header-bar-icon:link, .reports__header-bar-icon:visited, .reports__header-bar-icon {
        width: 3rem;
        height: 3rem;
        margin-right: 3rem;
        transition: all 0.3s;
        margin-top: 0.4rem; }
        .reports__header-bar-icon:link:hover, .reports__header-bar-icon:visited:hover, .reports__header-bar-icon:hover {
          transform: translateY(-0.2rem) scale(1.03);
          fill: var(--color-primary); }
        .reports__header-bar-icon:link:active, .reports__header-bar-icon:visited:active, .reports__header-bar-icon:active {
          transform: translateY(0.2rem);
          fill: var(--color-primary); } }
  .reports__subtitle {
    padding: 0 1.5rem;
    font-size: 1.2rem;
    margin-left: 3rem;
    color: var(--grey-dark-2);
    font-weight: 500; }
    @media (min-width: 56.25em) {
      .reports__subtitle {
        font-size: 1.6rem;
        padding: 0 3rem;
        margin-top: -1.8rem; } }
  .reports__via {
    padding: 0 1.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 3rem;
    margin-bottom: 0rem;
    color: var(--purple); }
    @media (min-width: 56.25em) {
      .reports__via {
        font-size: 1.3rem;
        padding: 0.3rem 3rem;
        margin-bottom: 1.5rem; } }
  .reports__heading {
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    margin-bottom: 1.8rem;
    color: var(--purple);
    text-align: center; }
    @media (min-width: 56.25em) {
      .reports__heading {
        font-size: 1.7rem;
        padding: 0.2rem 3rem; } }
  .reports__posted-date {
    font-size: 1rem !important;
    color: rgba(128, 128, 128, 0.5);
    margin-left: auto; }
    .reports__posted-date--dfp {
      font-size: 0.7rem !important; }

.breakdown__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0; }

.breakdown__item {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  margin: 0 1rem;
  align-items: center;
  justify-content: space-between; }
  .breakdown__item--pong {
    width: 100%;
    justify-content: center; }
  @media (min-width: 56.25em) {
    .breakdown__item--keyword {
      font-size: 1.7rem; } }
  .breakdown__item:nth-child(2n) {
    background-color: #44a3de14; }
  .breakdown__item--facebook:nth-child(2n) {
    background-color: #44a3de14; }
  @media (min-width: 56.25em) {
    .breakdown__item {
      font-size: 1.2rem;
      padding: 1rem; } }
  .breakdown__item--title {
    font-weight: 500;
    font-size: 1.8rem; }
  .breakdown__item--url {
    flex: 1;
    text-align: left;
    text-decoration: none;
    color: var(--purple);
    transition: all 0.3s;
    font-size: 1rem; }
    @media (min-width: 56.25em) {
      .breakdown__item--url {
        font-size: 1.4rem; } }
  .breakdown__item--name {
    font-size: 1.5rem; }
    @media (min-width: 56.25em) {
      .breakdown__item--name {
        font-size: 1.7rem; } }
  .breakdown__item--value {
    flex: 1;
    text-align: right;
    color: #9494be;
    font-size: 1.5rem; }
    @media (min-width: 56.25em) {
      .breakdown__item--value {
        font-size: 1.7rem;
        letter-spacing: 0.04rem; } }

.customer__icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.3rem;
  fill: currentColor; }

.details__traffic {
  width: 100%; }

.details__customer {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-primary);
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--grey-light-3); }
  .details__customer--dfp {
    font-size: 1rem; }
  .details__customerspan {
    font-weight: 500; }

.details__title:link, .details__title:visited, .details__title {
  text-align: none;
  text-decoration: none;
  color: var(--grey-dark-1);
  margin-top: 0.25rem;
  text-transform: capitalize;
  font-family: "Oswald", sans-serif;
  font-size: 2.1rem; }
  @media (min-width: 56.25em) {
    .details__title:link, .details__title:visited, .details__title {
      font-size: 3rem; } }

.details__title-center {
  color: var(--purple);
  margin-top: 1.3rem; }

.details__title-center, .details__title-center:link, .details__title-center:visited {
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500; }
  .details__title-center--nomargin, .details__title-center:link--nomargin, .details__title-center:visited--nomargin {
    margin-bottom: 0; }
  @media (min-width: 56.25em) {
    .details__title-center, .details__title-center:link, .details__title-center:visited {
      font-size: 2.2rem; } }

.details__subtitle {
  margin: 0.5rem 0;
  font-size: 1.4rem;
  font-family: sans-serif; }

.details__image-box {
  transition: all 0.5s;
  overflow: hidden; }
  @media (min-width: 37.5em) {
    .details__image-box:hover {
      transform: scale(1.01);
      box-shadow: var(--shadow-light); }
    .details__image-box:active {
      transform: translate(-0.2rem); } }
  .details__image-box--img {
    border-radius: 4px;
    width: 100%;
    height: 16rem;
    object-fit: cover;
    transition: all 0.2s;
    object-fit: cover; }
    @media (min-width: 37.5em) {
      .details__image-box--img {
        height: 22rem; } }

.details__graph-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem; }
  .details__graph-box img {
    max-width: 20rem; }
  .details__graph-box--traffic {
    flex-direction: row !important; }

.details__legend-dot {
  margin-right: 3px;
  width: 1rem;
  height: 1rem;
  border-radius: 50%; }
  .details__legend-dot--love {
    background-color: #f73737; }
  .details__legend-dot--like {
    background-color: #f0c472; }

.component-seperator {
  width: 100%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.068);
  margin: 1rem auto; }

.heading-fb-component {
  display: flex;
  align-items: center;
  transition: all 0.2s;
  margin-top: 1rem; }
  @media (min-width: 56.25em) {
    .heading-fb-component:hover {
      color: var(--color-primary);
      transform: scale(1.01); } }

.graph-component {
  background-color: rgba(98, 217, 255, 0.07); }

.facebook-icon {
  color: var(--fb);
  margin-right: 0.5rem; }

.thumbsup-icon {
  color: var(--fb); }

.beta-banner {
  background-color: salmon;
  margin: 0 auto;
  border-radius: 5px;
  width: 50%;
  margin-bottom: 2rem;
  cursor: default; }
  @media (min-width: 56.25em) {
    .beta-banner {
      border-radius: 6px;
      margin-top: -1rem; }
      .beta-banner:hover {
        animation: pulse 2s infinite; } }
  .beta-banner__version {
    text-transform: none;
    font-size: 0.8rem;
    letter-spacing: normal;
    display: none; }
    @media (min-width: 56.25em) {
      .beta-banner__version {
        display: initial; } }
  .beta-banner__text {
    color: var(--grey-light-1);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    text-align: center;
    z-index: 10; }
    @media (min-width: 56.25em) {
      .beta-banner__text {
        font-size: 1.4rem; } }

.campaign-dimensions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap; }
  .campaign-dimensions__item {
    text-align: center;
    padding: 0.5rem;
    border: 0.5px solid var(--grey-dark-2);
    width: 100%;
    height: 7rem;
    font-size: 1.2rem; }
    .campaign-dimensions__item:not(:last-child) {
      margin-bottom: 1rem; }

.facebook-section:link,
.facebook-section:visited,
.facebook-section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-decoration: none;
  color: var(--grey-dark-2); }

.facebook-section__heading {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--fb); }
  @media (min-width: 56.25em) {
    .facebook-section__heading {
      font-size: 1.5rem;
      font-weight: 500; } }

.facebook-section__title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--fb);
  font-family: "Roboto" sans-serif; }
  @media (min-width: 56.25em) {
    .facebook-section__title {
      font-size: 2.3rem;
      font-weight: 400; } }

@media (min-width: 56.25em) {
  .facebook-section__subtitle {
    font-size: 1.3rem; } }

.leaflet-container {
  height: 400px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  margin-bottom: 2rem; }

.leaflet-div-icon {
  width: 0;
  height: 0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: rgba(252, 16, 28, 0.315); }
  .leaflet-div-icon__text {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    color: white; }
  .leaflet-div-icon__dot {
    border-radius: 50%; }

.leaflet-div-icon-100 {
  background-color: #603cff;
  border-radius: 50% 50% 50% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; }
  .leaflet-div-icon-100__text {
    overflow: hidden;
    font-size: 1.1rem;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    color: white; }

.leaflet-div-icon-1000 {
  background-color: #c246b7;
  border-radius: 50% 50% 50% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20; }
  .leaflet-div-icon-1000__text {
    overflow: hidden;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    color: white; }

.leaflet-control-zoom {
  display: none !important; }

.report-error {
  position: fixed;
  z-index: 100;
  display: flex;
  height: 4rem;
  width: 70%;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #e65772;
  box-shadow: var(--shadow-light);
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0; }
  .report-error--content {
    top: 2rem; }
  .report-error--fborganic {
    top: 7rem; }
  .report-error--fbads {
    top: 12rem; }
  .report-error--dfp {
    top: 17rem; }
  .report-error__container {
    display: flex;
    align-items: center; }
  .report-error__icon {
    color: #fff;
    margin-right: 0.4rem;
    margin-top: 0.3rem; }
  .report-error__msg {
    color: #fff;
    font-size: 1.4rem; }
    @media (min-width: 56.25em) {
      .report-error__msg {
        font-size: 2rem; } }

.owner-request-section__title {
  font-size: 1.2rem;
  margin: 0 2rem;
  font-family: "Roboto";
  color: #303f9f;
  border-bottom: 1.2px dashed #303f9f3a; }

.no-traffic-data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: var(--shadow-light);
  font-size: 1.4rem;
  padding: 1rem;
  background-color: var(--grey-light-2);
  border-radius: 5px; }

@media (min-width: 75em) {
  .report {
    max-width: 90%;
    margin: 0 auto; } }

@media (min-width: 112.5em) {
  .report {
    max-width: 70%;
    margin: 0 auto; } }

.report__modal {
  background-color: #fefefe;
  padding: 1em;
  text-align: left; }

.report__panel-header {
  color: purple;
  font-size: 1.15rem;
  padding: 1rem 0; }
  @media (min-width: 37.5em) {
    .report__panel-header {
      font-size: 1.3rem;
      font-weight: 500; } }

.meta-box {
  display: flex;
  flex-direction: column; }
  @media (min-width: 37.5em) {
    .meta-box {
      align-items: center; } }

.article-container {
  display: flex;
  max-width: 780;
  margin: 1rem 0; }
  .article-container__articleImage {
    width: 33%;
    object-fit: cover;
    height: 11rem; }
  .article-container__content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem; }
    .article-container__content--title {
      padding: 0.2rem;
      font-size: 0.8rem;
      font-weight: 500;
      color: #131313; }
      @media (min-width: 37.5em) {
        .article-container__content--title {
          font-size: 1.4rem; } }

.insights-caption {
  font-size: 1.1rem;
  padding: 1rem 0;
  color: #575757;
  font-weight: 600;
  text-align: center; }

.analytics {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%; }
  @media (min-width: 37.5em) {
    .analytics {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly; } }
  @media (min-width: 37.5em) {
    .analytics__impressions {
      flex: 0 1 50%;
      order: -2;
      width: 50%; } }
  .analytics__metrics {
    text-align: center; }
    @media (min-width: 37.5em) {
      .analytics__metrics {
        flex: 0 1 50%; } }
  @media (min-width: 37.5em) {
    .analytics__reactions {
      flex: 0 1 50%;
      order: -1;
      width: 50%; } }
  .analytics__engagements {
    text-align: center; }
    @media (min-width: 37.5em) {
      .analytics__engagements {
        flex: 0 1 50%; } }

.editorial-insights__container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%; }
  @media (min-width: 37.5em) {
    .editorial-insights__container {
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap; } }
  .editorial-insights__container--traffic {
    width: 80%;
    margin: 0 auto; }
    @media (min-width: 37.5em) {
      .editorial-insights__container--traffic {
        flex: 0 0 80%;
        align-self: center; } }
  @media (min-width: 37.5em) {
    .editorial-insights__container--gender {
      flex: 0 1 50%;
      width: 50%; } }
  @media (min-width: 37.5em) {
    .editorial-insights__container--age {
      flex: 0 1 50%;
      width: 50%; } }

.revenue-insights {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100%;
  margin-top: 1rem; }

.revenue-insights-header,
.revenue-insights-subheader {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto; }

.revenue-insights-header {
  padding: 2rem 1rem; }

.revenue-insights-subheader {
  padding: 0 1rem 1rem 1rem; }

.revenue-insights-title {
  font-size: 2rem;
  white-space: nowrap; }

.revenue-insights-left {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.revenue-insights-right {
  display: flex; }

.Range
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2; }

.Range .DayPicker-Day {
  border-radius: 0 !important; }

.revenue-insights-datePicker__picker {
  min-width: 17rem;
  box-shadow: var(--shadow-light);
  height: 3rem;
  outline: none;
  background-color: rgba(226, 226, 226, 0.274);
  border: none;
  -webkit-appearance: none;
  padding-left: 1rem;
  font-size: 1.4rem;
  border-radius: 4px;
  color: var(--grey-dark-3); }

.revenue-insights-calendar {
  display: flex;
  align-items: center; }

.revenue-insights-filterButton {
  margin-left: 1rem;
  min-width: 13rem;
  width: 13rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s; }
  .revenue-insights-filterButton__text {
    margin-right: 0.5rem; }
  .revenue-insights-filterButton:hover {
    transform: scale(1.01) translateY(-2px); }
  .revenue-insights-filterButton:active {
    transform: scale(1) translateY(1px); }

.revenue-insights-clearButton {
  margin-left: 1rem;
  min-width: 7rem;
  width: 7rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s; }
  .revenue-insights-clearButton__text {
    margin-right: 0.5rem; }
  .revenue-insights-clearButton:hover {
    transform: scale(1.01) translateY(-2px); }
  .revenue-insights-clearButton:active {
    transform: scale(1) translateY(1px); }

.revenue-insights-stackedButton {
  margin-left: 1rem;
  min-width: 4rem;
  width: 4rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s; }
  .revenue-insights-stackedButton__text {
    margin-right: 0.5rem; }
  .revenue-insights-stackedButton:hover {
    transform: scale(1.01) translateY(-2px); }
  .revenue-insights-stackedButton:active {
    transform: scale(1) translateY(1px); }

.paginationButton {
  min-width: 8rem;
  width: 8rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s;
  user-select: none; }
  .paginationButton__text {
    margin-right: 0.5rem;
    user-select: none; }
  .paginationButton__text-left {
    margin-left: 0.5rem; }
  .paginationButton:hover {
    transform: scale(1.01) translateY(-2px); }
  .paginationButton:active {
    transform: scale(1) translateY(1px); }

.pagination-buttons {
  display: flex;
  align-items: center; }

.revenue-insights-goButton {
  margin-left: 1rem;
  min-width: 9rem;
  width: 9rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s;
  user-select: none; }
  .revenue-insights-goButton__text {
    margin-right: 0.5rem; }
  .revenue-insights-goButton:hover {
    transform: scale(1.01) translateY(-2px); }
  .revenue-insights-goButton:active {
    transform: scale(1) translateY(1px); }

.revenue-insights-chipArray {
  padding: 0 4rem; }
  .revenue-insights-chipArray > * {
    margin: 0.5rem;
    font-size: 0.5rem; }
    @media (min-width: 56.25em) {
      .revenue-insights-chipArray > * {
        margin: 0.3rem 0; } }

.revenue-insights-filter {
  margin-left: 1rem; }
  @media (min-width: 56.25em) {
    .revenue-insights-filter {
      margin-left: 0; } }
  .revenue-insights-filter > input {
    box-shadow: var(--shadow-light);
    width: 30vw;
    min-width: 15rem;
    max-width: 40rem;
    height: 3rem;
    outline: none;
    background-color: rgba(226, 226, 226, 0.274);
    border: none;
    -webkit-appearance: none;
    padding-left: 1rem;
    font-size: 1.4rem;
    border-radius: 4px; }

.revenue-table {
  table-layout: fixed;
  width: 94%;
  min-width: 135rem;
  margin: 0 auto;
  border-collapse: collapse;
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: var(--shadow-light); }
  .revenue-table__image {
    width: 5rem;
    max-width: 5rem;
    min-width: 5rem; }
  .revenue-table__article {
    width: 30rem;
    max-width: 30rem;
    min-width: 30rem; }
  .revenue-table__author {
    width: 16rem;
    min-width: 16rem; }
  .revenue-table__id {
    text-align: center;
    width: 20rem; }
  .revenue-table__chart {
    width: 5rem; }
  .revenue-table__footer {
    display: flex;
    padding: 1.4rem 0 0 0;
    justify-content: center; }
  .revenue-table__footer-rowAmount {
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem; }

.rev-table-head {
  font-size: 1.5rem; }

.rev-table-row:nth-child(even) {
  background-color: rgba(56, 45, 218, 0.021); }

.rev-table-row:not(:last-child) {
  border-bottom: 1px dashed #80808029; }
  .rev-table-row:not(:last-child):hover {
    transition: background-color 0.35s;
    background-color: rgba(64, 88, 197, 0.089); }

.rev-table-row__totals {
  background-color: var(--nmblue) !important;
  color: white !important; }

.revenue-table > th,
td {
  padding: 1rem 2.5rem; }

.table-collapse {
  padding: 0.4rem !important; }

.revenue-table > tbody :nth-child() {
  background-color: #3f19b5; }

.rev-table-item {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2rem;
  text-overflow: ellipsis; }
  .rev-table-item__chart {
    overflow: visible; }
  .rev-table-item__total {
    font-size: 1.3rem;
    font-weight: 500; }
  .rev-table-item__id {
    overflow: visible;
    max-width: 30px; }
  .rev-table-item__img {
    width: 10px;
    height: 10px; }
  .rev-table-item__impressions {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #f50057;
    justify-content: center;
    font-size: 1.3rem; }
  .rev-table-item__article {
    text-align: left !important; }
  .rev-table-item__article-icon {
    cursor: pointer; }
  .rev-table-item--article-url {
    color: var(--grey-dark-2);
    transition: all 0.2s; }
    @media (min-width: 56.25em) {
      .rev-table-item--article-url {
        cursor: pointer; } }
    .rev-table-item--article-url:hover {
      text-decoration: underline;
      color: var(--grey-dark-1); }

.revenue-table-head {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s; }

.rev-table-head {
  background-color: #3f19b5; }
  .rev-table-head__heading {
    font-family: "Roboto";
    padding: 0.8rem;
    font-weight: 400;
    color: #fff;
    user-select: none; }
    .rev-table-head__heading--name {
      text-align: left;
      padding-left: 2.5rem; }

.rev-table-button {
  cursor: pointer; }
  .rev-table-button:not(:last-child) {
    margin-right: 1rem; }
  .rev-table-button:hover {
    transition: transform 0.1s;
    transform: scale(1.15); }
  .rev-table-button__edit:hover {
    color: rgba(62, 62, 197, 0.918); }
  .rev-table-button__delete:hover {
    color: rgba(204, 54, 54, 0.829); }

.user-apps {
  display: inline-block;
  padding: 0.1rem 0.4rem;
  border-radius: 5px; }
  .user-apps:not(:last-child) {
    margin-right: 0.2rem; }
  .user-apps--RI {
    background-color: rgba(79, 184, 79, 0.938);
    color: white;
    text-transform: uppercase; }
  .user-apps--TI {
    background-color: rgba(181, 86, 27, 0.918);
    color: white;
    text-transform: uppercase; }
  .user-apps--PI {
    background-color: rgba(211, 104, 211, 0.918);
    color: white;
    text-transform: uppercase; }
  .user-apps--CI {
    background-color: #4019b5c0;
    color: white;
    text-transform: uppercase; }

.user-role {
  display: inline-block;
  text-transform: lowercase;
  padding: 0.1rem 0.4rem;
  border-radius: 5px; }
  .user-role:not(:last-child) {
    margin-right: 0.2rem; }
  .user-role--admin {
    background-color: #f9000085;
    color: white; }
  .user-role--prod {
    background-color: rgba(66, 66, 177, 0.74);
    color: white; }
  .user-role--client {
    background-color: rgba(83, 83, 83, 0.74);
    color: white; }

.admin-dialog-container {
  min-width: 20rem; }

.admin-dialog-text {
  font-size: 1.4rem; }
  .admin-dialog-text > *:not(:last-child) {
    margin-bottom: 1rem; }

.admin-dialog-actions {
  padding-top: 1rem;
  border-top: 1px dashed #0000002b;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column; }
  .admin-dialog-actions__header {
    text-align: center;
    margin-bottom: 0.5rem; }
  .admin-dialog-actions__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .admin-dialog-actions__select > select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 1.4rem;
    width: 7rem;
    height: 3.4rem;
    padding: 0.2rem; }
    .admin-dialog-actions__select > select:focus {
      outline: none; }
  .admin-dialog-actions__select > *:not(:last-child) {
    margin-right: 0.8rem; }

.pagination-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0; }
  .pagination-amount__item {
    width: 3rem;
    margin: 0.4rem;
    display: flex;
    background-color: var(--grey-light-2);
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 50%;
    box-shadow: var(--shadow-light);
    padding: 0.5rem;
    font-size: 1.4rem;
    color: var(--grey-dark-2);
    transition: all 0.2s;
    cursor: pointer;
    user-select: none; }
    .pagination-amount__item:hover {
      transform: scale(1.04) translateY(-1px); }
    .pagination-amount__item:active {
      transform: scale(1) translateY(2px); }
    .pagination-amount__item--selected {
      color: var(--nmblue);
      text-decoration: underline; }

.header-items {
  display: flex;
  justify-content: center;
  align-items: center; }
  .header-items__text {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 56.25em) {
      .header-items__text:hover {
        text-decoration: underline;
        transform: scale(1.02); } }
  .header-items__icon {
    margin-right: 0.2rem;
    transition: all 0.2s; }
    .header-items__icon:hover {
      transform: scale(1.02) translateY(-0.5px); }

.revenue-insights-numberFilterButton {
  min-width: 5rem;
  width: 5rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s; }
  .revenue-insights-numberFilterButton:not(:last-child) {
    margin-left: 1.2rem;
    margin-right: 0.5rem; }
  .revenue-insights-numberFilterButton__text {
    margin-right: 0.5rem; }
  .revenue-insights-numberFilterButton:hover {
    transform: scale(1.01) translateY(-2px); }
  .revenue-insights-numberFilterButton:active {
    transform: scale(1) translateY(1px); }

.numberFilter {
  display: flex;
  justify-content: center;
  align-items: center; }

.revenue-insights-numberFilter {
  box-shadow: var(--shadow-light);
  width: 10rem;
  max-width: 20rem;
  height: 3rem;
  outline: none;
  background-color: rgba(226, 226, 226, 0.274);
  border: none;
  -webkit-appearance: none;
  padding-left: 1rem;
  font-size: 1.4rem;
  border-radius: 4px;
  border: none; }
  .revenue-insights-numberFilter__select {
    margin-right: 0.5rem;
    height: 3rem;
    background-color: rgba(226, 226, 226, 0.274);
    box-shadow: var(--shadow-light) !important;
    border: none; }
    .revenue-insights-numberFilter__select:focus,
    .revenue-insights-numberFilter__select :active {
      outline: none; }

.revenue-chart-icon {
  cursor: pointer; }
  .revenue-chart-icon:hover {
    transition: all 0.1s;
    transform: translateY(-1px) scale(1.04); }
  .revenue-chart-icon:active {
    transform: translateY(2px) scale(1); }

.revenue-modal {
  width: 70rem;
  height: 36rem;
  top: 50%;
  left: 50%;
  margin-left: -35rem;
  margin-top: -15rem;
  background-color: var(--grey-light-2);
  box-shadow: var(--shadow-light);
  position: absolute;
  padding: 2rem; }
  .revenue-modal__title {
    font-size: 1.8rem;
    color: var(--grey-dark-1);
    font-weight: 500;
    margin-bottom: 1.1rem; }

.revenue-modal-graph {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 90%; }
  .revenue-modal-graph__checkboxes {
    margin-top: 0.5rem; }
  .revenue-modal-graph__checkboxes-list {
    list-style: none; }
  .revenue-modal-graph__graph {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
  .revenue-modal-graph__seperator {
    border-bottom: 1px dashed var(--grey-dark-3);
    background-color: var(--grey-dark-2);
    margin-bottom: 0.6rem; }
  .revenue-modal-graph__list-item {
    display: flex;
    align-items: center;
    font-size: 1.2rem; }
    .revenue-modal-graph__list-item:not(:last-child) {
      margin-bottom: 1rem; }

.revenue-button {
  margin-left: 1rem;
  min-width: 9rem;
  width: 9rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  transition: all 0.1s;
  user-select: none; }
  .revenue-button__text {
    margin-right: 0.5rem; }
  .revenue-button:hover {
    transform: scale(1.01) translateY(-1px); }
  .revenue-button:active {
    transform: scale(1) translateY(1px); }
  .revenue-button--uncheck-button {
    width: 7rem;
    min-width: 7rem;
    height: 2rem;
    font-size: 1rem;
    margin-left: 0; }

.user-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  padding: 2rem 1rem; }

.user-management-title {
  font-size: 2rem;
  white-space: nowrap; }

.user-management-filter {
  margin: 0 3rem; }
  .user-management-filter > input {
    box-shadow: var(--shadow-light);
    width: 30vw;
    min-width: 15rem;
    max-width: 40rem;
    height: 3rem;
    outline: none;
    background-color: rgba(226, 226, 226, 0.274);
    border: none;
    -webkit-appearance: none;
    padding-left: 1rem;
    font-size: 1.4rem;
    border-radius: 4px; }

.user-management {
  display: flex;
  margin: 0 auto; }

.user-table {
  table-layout: fixed;
  width: 94%;
  min-width: 80rem;
  overflow: scroll;
  margin: 0 auto;
  border-collapse: collapse;
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: var(--shadow-light);
  margin-bottom: 4rem; }

.table-head {
  font-size: 1.5rem; }

.table-row:nth-child(even) {
  background-color: rgba(56, 45, 218, 0.021); }

.table-row:not(:last-child) {
  border-bottom: 1px dashed #80808029; }

.table-row:hover {
  transition: background-color 0.35s;
  background-color: rgba(64, 88, 197, 0.089); }

.user-table--avatar {
  width: 8rem;
  max-width: 8rem;
  min-width: 8rem; }

.user-table > th,
td {
  padding: 1rem 2.5rem; }

.user-table > tbody :nth-child() {
  background-color: var(--nmblue); }

.table-item {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2rem;
  text-overflow: ellipsis; }
  .table-item__avatar {
    display: flex;
    justify-content: center; }
  .table-item__buttons {
    padding: 1rem;
    text-align: center; }
  .table-item__role {
    text-align: center; }
  .table-item__app {
    text-align: center; }

.table-head {
  background-color: var(--nmblue); }
  .table-head__heading {
    font-family: "Roboto";
    padding: 0.8rem;
    font-weight: 300;
    color: #fff; }
    .table-head__heading--name {
      text-align: left;
      padding-left: 2.5rem; }

.table-button {
  cursor: pointer; }
  .table-button:not(:last-child) {
    margin-right: 1rem; }
  .table-button:hover {
    transition: transform 0.1s;
    transform: scale(1.15); }
  .table-button__edit:hover {
    color: rgba(62, 62, 197, 0.918); }
  .table-button__delete:hover {
    color: rgba(204, 54, 54, 0.829); }

.user-apps {
  display: inline-block;
  padding: 0.1rem 0.4rem;
  border-radius: 5px; }
  .user-apps:not(:last-child) {
    margin-right: 0.2rem; }
  .user-apps--RI {
    background-color: rgba(79, 184, 79, 0.938);
    color: white;
    text-transform: uppercase; }
  .user-apps--PI {
    background-color: rgba(211, 104, 211, 0.918);
    color: white;
    text-transform: uppercase; }
  .user-apps--CI {
    background-color: #4019b5c0;
    color: white;
    text-transform: uppercase; }
  .user-apps--AP {
    background-color: #18dae8c0;
    color: white;
    text-transform: uppercase; }

.user-role {
  display: inline-block;
  text-transform: lowercase;
  padding: 0.1rem 0.4rem;
  border-radius: 5px; }
  .user-role:not(:last-child) {
    margin-right: 0.2rem; }
  .user-role--admin {
    background-color: #f9000085;
    color: white; }
  .user-role--prod {
    background-color: rgba(66, 66, 177, 0.74);
    color: white; }
  .user-role--client {
    background-color: rgba(83, 83, 83, 0.74);
    color: white; }

.admin-dialog-container {
  min-width: 20rem; }

.admin-dialog-text {
  font-size: 1.4rem; }
  .admin-dialog-text > *:not(:last-child) {
    margin-bottom: 1rem; }

.admin-dialog-actions {
  padding-top: 1rem;
  border-top: 1px dashed #0000002b;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column; }
  .admin-dialog-actions__header {
    text-align: center;
    margin-bottom: 0.5rem; }
  .admin-dialog-actions__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .admin-dialog-actions__select > select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 1.4rem;
    width: 7rem;
    height: 3.4rem;
    padding: 0.2rem; }
    .admin-dialog-actions__select > select:focus {
      outline: none; }
  .admin-dialog-actions__select > *:not(:last-child) {
    margin-right: 0.8rem; }

.report-custom-container {
  display: flex;
  margin: 2rem auto;
  width: 80%;
  justify-content: center; }

.reportCustom {
  box-shadow: var(--shadow-light);
  min-width: 30rem;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: column; }
  @media (min-width: 56.25em) {
    .reportCustom {
      flex-direction: row; } }

.reportCustom-images {
  display: flex;
  height: auto;
  width: 100%; }
  @media (min-width: 56.25em) {
    .reportCustom-images {
      display: flex;
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%; } }
  .reportCustom-images__image {
    display: flex;
    object-fit: contain;
    height: 30rem !important; }
    .reportCustom-images__image:not(:first-child) {
      margin-left: 0.2rem; }
    @media (min-width: 56.25em) {
      .reportCustom-images__image {
        width: unset;
        display: flex;
        object-fit: contain;
        padding: 0.3rem; } }
  .reportCustom-images__title {
    justify-content: center;
    width: 100%;
    display: flex;
    font-family: "Oswald";
    font-size: 3.2rem;
    color: var(--purple); }

.reportCustom-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  align-items: center; }
  .reportCustom-content__title {
    font-size: 2.6rem;
    font-weight: 500;
    color: var(--grey-dark-1); }
  .reportCustom-content__list {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    max-width: 50rem;
    overflow: auto;
    flex-wrap: wrap; }

.content-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem; }
  .content-items__label {
    font-size: 1.4rem;
    color: #777;
    color: var(--grey-dark-2);
    text-transform: capitalize; }
  .content-items__value {
    color: var(--grey-dark-1);
    font-size: 1.6rem;
    font-weight: 700;
    font-family: arial, sans-serif; }

.traffic-button {
  margin-left: 1rem;
  min-width: 9rem;
  width: 9rem;
  height: 3rem;
  border-radius: 7px;
  font-size: 1.4rem;
  font-weight: 400;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: var(--shadow-light);
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  border: 1px solid var(--grey-light-4);
  align-items: center;
  cursor: pointer;
  user-select: none; }
  .traffic-button__text {
    color: var(--grey-dark-2);
    margin-right: 0.5rem; }
  .traffic-button:hover {
    transform: scale(1.01) translateY(-2px); }
  .traffic-button:active {
    transform: scale(1) translateY(1px); }
  .traffic-button--searchByUrl {
    width: 12rem; }
  .traffic-button--searchByName {
    width: 9rem; }

.ti-header-bar {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-bottom: 1rem; }
  @media (min-width: 56.25em) {
    .ti-header-bar {
      flex-direction: row; } }
