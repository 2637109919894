html {
    height: 100%;
    width: 100%;
}
body {
    height: 100%;
    width: 100%;
}
#App {
    width: 100%;
    margin: 0 auto;
}

#root {
    height: 100%;
    width: 100%;
}

#Content {
    height: 100%;
    width: 100%;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

a {
    text-decoration: none;
}

.Selectable
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}
.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}
